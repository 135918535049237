<script>
import { wpService } from "@/services/wp";
import { mapGetters } from "vuex";
import avatarUploader from "@/components/organization/avatar-uploader.vue";

export default {
  computed: {
    ...mapGetters("config", ["user_punchline_length", "user_biography_length"]),
    ...mapGetters("session", ["accOrgData"]),
  },

  methods: {
    async deleteOrgAvatar() {
      const loader = this.$buefy.loading.open();
      await wpService.organizations
        .deleteOrgAvatar()
        .then(response => {
          console.log(response);
        })
        .catch(err => {
          console.log(err);
        });
      loader.close();
    },
    openAvatarPicker() {
      this.$sitio.modal.open({
        component: avatarUploader,
        parent: this,
      });
    },
  },
};
</script>

<template>
  <form id="orgInfoEditor" action="">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Editor</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <div class="field section--photo">
          <h3 class="label">Foto de perfil</h3>
          <div class="avatar">
            <div class="avatar-container">
              <img class="size-holder" src="@/assets/sizeholder-square.png" />
              <picture v-if="accOrgData.org_avatar">
                <source :srcset="accOrgData.org_avatar.srcset" />
                <img :src="accOrgData.org_avatar.src" />
              </picture>
              <picture v-else>
                <img src="@/assets/avatar-square.svg" />
              </picture>
            </div>
          </div>
          <div class="buttons instructions">
            <!-- <p>
              Formato: archivo JPEG<br />
              Tamaño del archivo: inferior a 10 MB<br />
              Dimensiones: por lo menos 400 por 400 píxeles
            </p> -->
            <!-- <avatarPicker text="Cargar imagen" /> -->
            <button
              class="button"
              v-if="accOrgData.org_avatar"
              @click.prevent="deleteOrgAvatar"
            >
              Eliminar foto
            </button>
            <button class="button is-primary" @click.prevent="openAvatarPicker">
              Cargar imagen
            </button>
          </div>
        </div>
        <b-field label="Nombre de la Organización">
          <b-input type="text" :value="accOrgData.org_name" required> </b-input>
        </b-field>

        <b-field label="Sitio URL">
          <p class="control">
            <span class="button is-static">sitio.do/orgs/</span>
          </p>
          <b-input :value="accOrgData.org_slug" expanded />
        </b-field>

        <b-field label="Lema de la organización">
          <b-input
            maxlength="120"
            :value="accOrgData.org_punchline"
            type="textarea"
          ></b-input>
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <b-button label="Close" @click="$emit('close')" />
        <b-button label="Guardar" type="is-primary" />
      </footer>
    </div>
  </form>
</template>

<style lang="scss">
// .orgInfoEditor_modal {
//   .modal-content {
//     width: auto;
//   }
// }
</style>

<style lang="scss" scoped>
.modal_bar,
.modal_body {
  padding: 1rem;
}
.modal_bar {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
}
.modal_title {
  font-size: 1.125rem;
  font-weight: 500;
  color: #000;
}
.orgInfoEditor {
  background-color: #fff;
  width: 100%;
  max-width: 40rem;
}

.section--photo {
  .avatar {
    width: 10rem;
    .avatar-container {
      overflow: hidden;
      position: relative;
    }
    .size-holder {
      width: 100%;
      display: block;
    }
    picture {
      display: block;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 0.25rem;
      overflow: hidden;
      img {
        display: block;
        object-fit: cover;
        object-position: 50% 50%;
        width: 100%;
        height: 100%;
      }
    }
  }
  .instructions {
    font-size: 0.875em;
    padding-top: 1rem;
  }
}
</style>

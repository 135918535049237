<script>
import { wpService } from "@/services/wp";
import dataURLtoBlob from "blueimp-canvas-to-blob";
import loadImage from "blueimp-load-image";
import Cropper from "cropperjs";

export default {
  data() {
    return {
      accept: "image/jpeg, image/png",
      pickedFile: null,
      queuedFile: null,
      imgName: null,
      imgType: null,
      imgSrc: null,
      cropper: {},
      destination: {},
      cropped: null,
      cropping: {
        minHeight: 256,
      },
    };
  },

  // mounted() {
  // },

  methods: {
    async prepareImage(file) {
      const loader = this.$buefy.loading.open();
      let result = await new Promise(resolve => {
        loadImage(
          file,
          function(image) {
            if (image.type === "error") {
              resolve(null);
            } else {
              resolve(image.toDataURL(file.type, 1));
            }
          },
          {
            maxWidth: 2000,
            maxHeight: 2000,
            canvas: true,
            orientation: true,
          },
        );
      });

      this.imgName = file.name;
      this.imgType = file.type;
      this.imgSrc = result;

      /**
       * 1. Pass the base64 to the cropper modal as a prop
       * 2. In the modal use this a the image src
       * 3. Process adjust the image
       * 4. Before upload, reprocess and resize if larger than needed
       */
      loader.close();
    },

    cropperInit(e) {
      console.log(e);
      // this.cropper = new Cropper(this.image, {
      // this.cropper = new Cropper(e.path[0], {
      this.cropper = new Cropper(this.$refs.image, {
        viewMode: 1,
        toggleDragModeOnDblclick: false,
        cropBoxMovable: false,
        cropBoxResizable: false,
        autoCropArea: 1,
        // autoCrop: false,
        guides: false,
        // center: true,
        // responsive: false,
        // highlight: false,
        // modal: false,
        // background: false,
        scalable: false,
        // zoomable: false,
        wheelZoomRatio: 0.1,
        minContainerWidth: 200,
        minContainerHeight: 200,
        // minCropBoxWidth: 200,
        // minCropBoxHeight: 200,
        // minCanvasWidth: 400,
        // minCanvasHeight: 400,
        aspectRatio: 1,
        // preview: ".author-chip .chip__image",
        // crop: () => {
        //   const canvas = this.cropper.getCroppedCanvas();
        //   this.destination = canvas.toDataURL("image/jpg");
        // },
        dragMode: "move",
      });
    },

    getCroppedCanvas() {
      console.log(this.cropper.getCroppedCanvas(this.cropping));
    },

    async setCroppedCanvas() {
      let canvas = this.cropper.getCroppedCanvas(this.cropping);
      // console.log("canvas", canvas);
      let base64 = canvas.toDataURL(this.imgType);
      // console.log("base64", base64);
      // this.cropped = base64;
      let binary = dataURLtoBlob(base64);
      // console.log("binary", binary);
      let file = await this.processFile(binary);
      if (file) {
        const loader = this.$buefy.loading.open();
        await wpService.organizations
          .postOrgAvatar(this.imgName, this.imgType, file)
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            this.$parent.close();
            loader.close();
          });
      }
    },

    async processFile(file, returnBlob = true) {
      // TODO: Check if browser supports next functions
      // let testing = true;
      // if (testing) return Promise.resolve(null);
      return new Promise(resolve => {
        let $this = this;
        loadImage(
          file,
          function(image) {
            if (image.type === "error") {
              $this.$buefy.toast.open({
                message: `Error al procesar imagen "${file.name}"`,
                type: "is-danger",
              });
              resolve(null);
            } else {
              let b64src = image.toDataURL(file.type, 1);
              let blob = dataURLtoBlob(b64src);
              if (blob.size >= 1000000) {
                b64src = image.toDataURL(file.type, 0.8);
              }
              resolve(returnBlob ? dataURLtoBlob(b64src) : b64src);
            }
          },
          {
            maxWidth: 800,
            maxHeight: 800,
            canvas: true, // return canvas instead of <img>
            orientation: true,
          },
        );
      });
    },
  },

  watch: {
    async pickedFile() {
      if (!this.pickedFile) return;
      await this.prepareImage(this.pickedFile);
      this.pickedFile = null;
    },
  },
};
</script>

<template>
  <div id="avatar-uploader">
    <h1 class="title modal-title">Avatar</h1>
    <div v-if="!imgSrc">
      <b-field>
        <b-upload
          v-model="pickedFile"
          :accept="accept"
          :disabled="!!pickedFile"
          drag-drop
        >
          <section class="section">
            <div class="content has-text-centered">
              <p>
                <b-icon icon="upload" size="is-large" />
              </p>
              <p>Drop your files here or click to upload</p>
            </div>
          </section>
        </b-upload>
      </b-field>
    </div>
    <div v-else>
      <div class="img-container">
        <img ref="image" @load="cropperInit" :src="imgSrc" crossorigin />
      </div>
      <hr />
      <div class="buttons">
        <button class="button" @click="getCroppedCanvas">getdata</button>
        <button class="button is-link" @click="setCroppedCanvas">
          Guardar
        </button>
      </div>
    </div>
    <img v-if="cropped" :src="cropped" />
  </div>
</template>

<style src="cropperjs/dist/cropper.css"></style>

<style lang="scss" scoped>
#avatar-uploader {
  width: 100%;
  max-width: 30em;
}
.modal-title {
  margin-top: -1rem;
  font-weight: 900;
}
.buttons {
  justify-content: flex-end;
  // margin-top: 1.5rem;
  margin-bottom: -2rem;
}
/deep/ .upload,
/deep/ .upload .upload-draggable {
  width: 100%;
}
.img-container {
  width: 100%;
  max-height: 20rem;
  border-radius: 0.25rem;
  overflow: hidden;
}
/deep/ .cropper-modal {
  opacity: 0.15;
}
/deep/ .cropper-canvas::after {
  content: "";
  display: block;
  position: absolute;
  background-color: #fff;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.5;
}
/deep/ .cropper-view-box {
  outline: none;
  background-color: #fff;
}
/deep/ .cropper-face {
  border: 1px solid;
  border-image-slice: 1;
  border-image-repeat: repeat;
  border-image-source: url("data:image/gif;base64,R0lGODlhCgAKAJECAAAAAP///////wAAACH/C05FVFNDQVBFMi4wAwEAAAAh/wtYTVAgRGF0YVhNUDw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OEI5RDc5MTFDNkE2MTFFM0JCMDZEODI2QTI4MzJBOTIiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OEI5RDc5MTBDNkE2MTFFM0JCMDZEODI2QTI4MzJBOTIiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuZGlkOjAyODAxMTc0MDcyMDY4MTE4MDgzQzNDMjA5MzREQ0ZDIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjAyODAxMTc0MDcyMDY4MTE4MDgzQzNDMjA5MzREQ0ZDIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+Af/+/fz7+vn49/b19PPy8fDv7u3s6+rp6Ofm5eTj4uHg397d3Nva2djX1tXU09LR0M/OzczLysnIx8bFxMPCwcC/vr28u7q5uLe2tbSzsrGwr66trKuqqainpqWko6KhoJ+enZybmpmYl5aVlJOSkZCPjo2Mi4qJiIeGhYSDgoGAf359fHt6eXh3dnV0c3JxcG9ubWxramloZ2ZlZGNiYWBfXl1cW1pZWFdWVVRTUlFQT05NTEtKSUhHRkVEQ0JBQD8+PTw7Ojk4NzY1NDMyMTAvLi0sKyopKCcmJSQjIiEgHx4dHBsaGRgXFhUUExIREA8ODQwLCgkIBwYFBAMCAQAAIfkEBQoAAgAsAAAAAAoACgAAAhWEERkn7W3ei7KlagMWF/dKgYeyGAUAIfkEBQoAAgAsAAAAAAoACgAAAg+UYwLJ7RnQm7QmsCyVKhUAIfkEBQoAAgAsAAAAAAoACgAAAhCUYgLJHdiinNSAVfOEKoUCACH5BAUKAAIALAAAAAAKAAoAAAIRVISAdusPo3RAzYtjaMIaUQAAIfkEBQoAAgAsAAAAAAoACgAAAg+MDiem7Q8bSLFaG5il6xQAIfkEBQoAAgAsAAAAAAoACgAAAg+UYRLJ7QnQm7SmsCyVKhUAIfkEBQoAAgAsAAAAAAoACgAAAhCUYBLJDdiinNSEVfOEKoECACH5BAUKAAIALAAAAAAKAAoAAAIRFISBdusPo3RBzYsjaMIaUQAAOw==");
  background-color: transparent;
  opacity: 1;
}
</style>

<script>
import { wpService } from "@/services/wp";
import _includes from "lodash/includes";
import _findIndex from "lodash/findIndex";
import orgInfoEditor from "@/components/org-info-editor";
// import axios from "axios";
import { mapGetters } from "vuex";

export default {
  components: {
    "org-hero": () =>
      import(
        /* webpackChunkName: "org-hero" */ "@/components/organization/org-hero.vue"
      ),
    "organization-listings": () =>
      import(
        /* webpackChunkName: "organization-listings" */ "@/components/organization-listings.vue"
      ),
    "organization-members": () =>
      import(
        /* webpackChunkName: "organization-members" */ "@/components/organization-members.vue"
      ),
    "organization-offices": () =>
      import(
        /* webpackChunkName: "organization-offices" */ "@/components/organization-offices.vue"
      ),
  },

  metaInfo() {
    const title = this.pageTitle;
    return {
      title,
    };
  },

  data() {
    return {
      organization: null,
      activeTab: null,
      hasHero: false,
      showEditor: false,
    };
  },

  computed: {
    ...mapGetters("session", ["org_member_can"]),
    CAN_EDIT_ORG_PROFILE() {
      return this.org_member_can("EDIT_ORG_PROFILE", this.organization.org_id);
    },
    pageTitle() {
      return this.organization ? this.organization.org_name : undefined;
    },
    org_slug() {
      return this.$route.params.org_slug || null;
    },
    tabs() {
      let tabs = [
        {
          title: "Publicaciones",
          name: "organization-listings",
          active: _includes(
            ["organization-listings", "organization-listings-single"],
            this.$route.name,
          ),
        },
        {
          title: "Miembros",
          name: "organization-members",
          active: this.$route.name === "organization-members",
        },
        {
          title: "Oficinas",
          name: "organization-offices",
          active: this.$route.name === "organization-offices",
        },
      ];
      return tabs;
    },
    currentTabIndex() {
      return _findIndex(this.tabs, i => i.active);
    },
    currentTabName() {
      return this.currentTabIndex < 0
        ? ""
        : this.tabs[this.currentTabIndex].name;
    },
  },

  methods: {
    openInfoEditor() {
      this.$buefy.modal.open({
        parent: this.$root,
        customClass: "orgInfoEditor_modal",
        // hasModalCard: true,
        component: orgInfoEditor,
      });
    },
    tabClick(tab, index) {
      console.log(tab, index);
    },
  },

  mounted() {
    wpService.organizations
      .getOrgInfo(this.org_slug)
      .then(data => {
        this.organization = data;
        this.organization.org_cover = {
          src:
            "https://media-exp1.licdn.com/dms/image/C4D1BAQEKJP61rPMmbQ/company-background_10000/0/1625236016927?e=1628067600&v=beta&t=BJNmFR_xAR4jtq8BELA2FG-aLLh_pxPB_ha77eo51Ss",
        };
      })
      .catch(err => {
        console.log(err);
      });
    // const gift_id = Number(this.$route.query.id) || 0;
    // if (gift_id < 1) {
    //   return this.$router.replace({ name: "home" });
    // }
  },
};
</script>

<template>
  <sitio-layout id="single-organization">
    <!-- <div v-if="!organization" class="loading">.....</div> -->
    <b-loading v-if="!organization" :active="true" />
    <div v-else class="app__view">
      <header class="profile_head" :class="{ hasHero }">
        <org-hero
          v-if="organization.org_cover"
          :value="organization.org_cover.src"
          class="head_hero"
        ></org-hero>
        <!-- <div class="head_hero" v-if="hasHero">
          <img :src="hasHero.src" />
        </div> -->
        <!-- <div class="no_hero">
          NOHERO
        </div> -->
        <div class="head_content">
          <div class="head_info">
            <div class="head_card">
              <div class="org_avatar">
                <img
                  v-if="!organization.org_avatar"
                  src="@/assets/avatar-square.svg"
                />
                <img
                  v-if="organization.org_avatar"
                  :src="organization.org_avatar.src"
                  :srcset="organization.org_avatar.srcset"
                />
              </div>
              <div class="fields">
                <h1 class="org_name">{{ organization.org_name }}</h1>
                <p class="org_address">{{ organization.org_address }}</p>
              </div>
            </div>
            <p class="org_punchline">{{ organization.org_punchline }}</p>
          </div>
          <div class="head_options">
            <div class="buttons">
              <router-link
                v-if="CAN_EDIT_ORG_PROFILE"
                class="button is-link"
                :to="{ name: 'acc-org' }"
                >Administrar</router-link
              >
              <b-button
                v-if="CAN_EDIT_ORG_PROFILE"
                type="is-link"
                class=""
                @click="openInfoEditor"
                >Editar</b-button
              >
            </div>
          </div>
          <!-- <p class="website">{{ organization.org_website }}</p> -->
        </div>
      </header>

      <nav class="content_width organization_tabs">
        <div class="tabs_group">
          <router-link
            :to="{ name: tab.name }"
            @click.prevent="tabClick(tab, index)"
            v-for="(tab, index) in tabs"
            :key="index"
            class="tab"
            :class="{ active: tab.active }"
          >
            {{ tab.title }}
          </router-link>
        </div>
      </nav>

      <section
        class="content_width profile_section section--members"
        v-if="$route.name === 'organization-members'"
      >
        <div class="">
          <!-- <div class="section__title">Miembros</div> -->
          <organization-members :org_id="organization.org_id" />
        </div>
      </section>

      <section
        class="content_width profile_section section--offices"
        v-if="$route.name === 'organization-offices'"
      >
        <div class="">
          <!-- <div class="section__title">Miembros</div> -->
          <organization-offices :org_id="organization.org_id" />
        </div>
      </section>

      <section
        v-if="currentTabName === 'organization-listings'"
        class="content_width section--listings"
      >
        <!-- <div class="section__title">Publicaciones</div> -->
        <organization-listings :org_id="organization.org_id" />
      </section>

      <section v-if="false" class="profile_section content_width">
        <pre>{{ organization }}</pre>
      </section>
    </div>
  </sitio-layout>
</template>

<style lang="scss">
html[route^="organization-"] {
  background-color: var(--sitio-bg-semi);
}
</style>

<style lang="scss" scoped>
#single-organization {
  --sitio-desktop-nav-height: 3.75rem;
}
.page {
  padding: 2rem;
}

section {
  margin-bottom: var(--sitio-section-gap);
  box-shadow: var(--sitio-artdeco-shadow);
  background-color: #fff;
  padding: var(--sitio-spacing);
  @media (min-width: 1128px) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.profile_head {
  margin: 0 auto;
  max-width: var(--sitio-max-content-width);
  box-shadow: var(--sitio-artdeco-shadow);
  .head_hero {
    // height: 20vmin;
    max-height: 30vmin;
    overflow: hidden;
    display: flex;
    img {
      width: 100%;
      display: block;
      object-fit: cover;
    }
  }
  .head_content {
    background-color: #fff;
    padding: 1rem var(--sitio-spacing);
    display: flex;
    gap: 1rem;
  }
  .head_info {
    flex: 1 1 auto;
  }
  .head_options {
    text-align: right;
  }
  .head_card {
    display: flex;
    align-items: flex-end;
    .fields {
      margin-left: 1rem;
    }
  }
  .org_avatar {
    // height: 0;
    img {
      display: block;
      border: 2px solid #fff;
      // transform: translateY(-100%);
      width: 4.25rem;
      height: 4.25rem;

      box-shadow: var(--sitio-artdeco-shadow);
    }
  }
  .head_hero + .head_content {
    .org_avatar {
      height: 0;
      img {
        transform: translateY(-100%);
      }
    }
  }
  .org_name {
    font-size: 1.125rem;
    font-weight: 500;
  }
  .org_address {
    font-size: 0.875rem;
  }
  .org_punchline {
    margin-top: 0.75rem;
    font-weight: 500;
    color: #333;
  }
  @media (max-width: 640px) {
    .head_content {
      flex-direction: column;
    }
  }
  @media (min-width: 40em) {
    .org_avatar {
      img {
        width: 7rem;
        height: 7rem;
      }
    }
    .org_name {
      font-size: 1.5rem;
    }
  }
}

.organization_tabs {
  margin-top: var(--sitio-section-gap);
  background-color: #fff;
  box-shadow: var(--sitio-artdeco-shadow);
  position: sticky;
  top: -1px;
  z-index: 2;
  .tabs_group {
    display: flex;
    margin-left: -0.5rem;
  }
  .tab {
    font-size: 0.875rem;
    font-weight: 500;
    margin-right: 1rem;
    padding: 1rem 0.5rem;
    color: #555;
    position: relative;
    &.active {
      color: $link;
      &::after {
        content: "";
        display: block;
        margin-bottom: -1rem;
        border-style: solid;
        border-color: $link;
        border-width: 0 0 2px;
        margin-bottom: -1rem;
        padding-top: 1rem;
      }
    }
  }
}

/deep/ .swiper {
  .swiper-wrapper {
    align-items: stretch;
  }
  .member__outer {
    color: #666;
    &:not(:last-child) {
      padding-right: 1rem;
    }
    .member__inner {
      height: 100%;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      border: 1px solid rgb(235, 235, 235);
      overflow: hidden;
      border-radius: 5px;
      .user_avatar img {
        display: block;
        width: 100%;
      }
      .user_info {
        padding: 8%;
        font-size: 0.9375rem;
      }
      .user_name {
        color: #000;
        font-weight: bold;
      }
    }
  }
  .member__outer {
    width: 33%;
    height: auto;
    padding-bottom: 8px;
    @media (min-width: 425px) {
      width: 26%;
    }
    @media (min-width: 425px) {
      width: 26%;
    }
    @media (min-width: 640px) {
      width: 20%;
    }
    @media (min-width: 860px) {
      width: 16.5%;
    }
    @media (min-width: 1024px) {
      width: 14%;
    }
    @media (min-width: 1200px) {
      width: 12%;
    }
  }
  @media (max-width: 639px) {
    margin-left: calc(var(--sitio-spacing) * -1);
    margin-right: calc(var(--sitio-spacing) * -1);
    .member__outer {
      padding-left: var(--sitio-spacing);
    }
    .member__outer:not(:last-child) {
      padding-right: 0;
      margin-right: calc(var(--sitio-spacing) * -1 + 1rem);
    }
    .member__outer:last-child {
      padding-right: var(--sitio-spacing);
    }
  }
}
</style>
